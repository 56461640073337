<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import type { UnwrapRef } from 'vue'

const { t, locale, locales, setLocale } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const localeFlagMap: Record<UnwrapRef<typeof selectedLocale>, string> = {
  en: 'i-circle-flags:us',
  cs: 'i-circle-flags:cz',
  sk: 'i-circle-flags:sk',
  hu: 'i-circle-flags:hu',
  pl: 'i-circle-flags:pl',
  ro: 'i-circle-flags:ro',
}

const showCountryBottomSheet = ref(false)
const showCountrySelectBottomSheet = ref(false)

const localeObject = computed<LocaleObject & { country: string }>(() => locales.value.find(l => l.code === locale.value))
const localeCountryTranslation = computed(() => t(`country.${localeObject.value.country.toLowerCase() === 'us' ? 'other' : localeObject.value.country.toLowerCase()}`))
const localeCountries = computed(() => locales.value.map(l => ({
  ...l,
  translation: t(`country.${l.country.toLowerCase() === 'us' ? 'other' : l.country.toLowerCase()}`),
}))
// sort alphabetically by the translation
  .sort((a, b) => a.translation.localeCompare(b.translation))
// default locale with country US should be at the end of the list because it's the catch-all locale that is displayed as "Other" in the UI
  .sort((a, _b) => a.country === 'US' ? 1 : -1))

async function updateLocale(locale: string) {
  await setLocale(locale)
  switchLocalePath(locale)
  window.location.reload()
}
</script>

<template>
  <div
    role="region"
    :aria-label="t('language_and_country_change')"
    class="relative cursor-pointer"
  >
    <button
      class="border n-border-base rd-1 border-solid px3.25 py3"
      data-testid="languageCountrySwitch"
      @click="showCountryBottomSheet = true"
    >
      <div class="flex items-center">
        <NIcon
          :icon="localeFlagMap[locale]"
          alt="flag"
          class="mr1 aspect-5/4 w5 rd-0 rounded-full align-bottom shadow-sm"
          data-testid="languageCountrySwitchSelectedCountryFlag"
        />
        <span :data-testid="`languageCountrySwitchSelectedCountry_${localeObject.country}`" class="text-xs fw-600 leading-none">
          {{ localeObject.country }}
        </span>
      </div>
    </button>
  </div>

  <NBottomSheet
    :show="showCountryBottomSheet"
    closable
    @close="showCountryBottomSheet = false"
  >
    <template #header>
      <header
        data-testid="languageCountrySwitchFlyoutHeader"
        class="flex items-center justify-between px4 pt4 sm:px-6"
      >
        <div class="w5" />
        <span class="mb0.5 pb1.5 pt2 text-center text-xl fw-600 leading-none" n="slate9 lg">
          {{ t('form.country.label') }}
        </span>

        <div class="w5 flex items-center">
          <button id="close-sheet" @click="showCountryBottomSheet = false">
            <NIcon icon="i-heroicons:x-mark" n="lg" />
          </button>
        </div>
      </header>
    </template>

    <button
      class="cursor-pointer border-none bg-none px4 py6 outline-none"
      data-testid="languageCountrySwitchCountriesOpener"
      @click="showCountrySelectBottomSheet = true"
    >
      <div
        class="wfull flex items-center justify-between"
        data-testid="languageCountrySwitchCountriesOpenerContent"
      >
        <span class="mb0.5 text-sm fw600">
          {{ t('current_store') }}
        </span>
        <div class="flex items-center">
          <NIcon
            :icon="localeFlagMap[locale]"
            class="mb1 mr2 aspect-5/4 h4 w4 rd-0 align-middle"
          />
          <span
            :data-testid="`languageCountrySwitchCountriesOpenerContentLabel-${localeCountryTranslation}`"
            class="mb0.5 text-sm fw600"
          >
            {{ localeCountryTranslation }}
          </span>

          <NIcon icon="i-ri:arrow-right-s-line" class="ml3 text-slate-400" n="lg" />
        </div>
      </div>
    </button>
  </NBottomSheet>

  <NBottomSheet
    :show="showCountrySelectBottomSheet"
    closable
    @close="showCountrySelectBottomSheet = false"
  >
    <template #header>
      <header
        role="button"
        tabindex="0"
        data-testid="languageCountrySwitchFlyoutCountriesHeader"
        class="wfull flex shrink-0 cursor-pointer items-center justify-between border-b-none px4 py4 sm:px-6"
        @click="showCountryBottomSheet = true"
      >
        <div class="w5" />
        <span class="mb0.5 pb1.5 pt2 text-center text-xl fw-600 leading-none" n="slate9 lg">
          {{ t('select_a_country') }}
        </span>

        <div class="w5 flex items-center">
          <button id="close-sheet" @click="showCountrySelectBottomSheet = false">
            <NIcon icon="i-heroicons:x-mark" n="lg" />
          </button>
        </div>
      </header>
    </template>

    <div class="overflow-y-auto">
      <div class="wfull flex flex-col bg-white">
        <a
          v-for="localeCountry in localeCountries"
          :key="localeCountry.code"
          :href="`/${localeCountry.code}`"
          :data-testid="`languageCountrySwitchCountry-${localeCountry.translation}`"
          class="box-border h14 wfull flex shrink-0 cursor-pointer items-center border-t n-border-base bg-white px4 leading-none hover:bg-slate-50"
          target="_self"
          rel="noreferrer"
          @click.prevent="updateLocale(localeCountry.code)"
        >
          <NIcon
            v-if="localeCountry.country.toLowerCase() === 'us'"
            icon="i-ri:global-line"
            class="mr2 aspect-5/4 rd-0 align-bottom"
          />
          <NIcon
            v-else
            :icon="localeFlagMap[localeCountry.code]"
            class="mr2 aspect-5/4 h4 w4 rd-0 align-bottom"
          />
          <span data-testid="languageCountrySwitchCountryLabel" class="lifcTP sc-1kpot6a-2">
            {{ localeCountry.translation }}
          </span>

          <NIcon
            v-if="localeCountry.code === localeObject.code"
            icon="i-ri:check-line"
            class="ml-auto h5 w5"
          />
        </a>
      </div>
    </div>
  </NBottomSheet>
</template>
